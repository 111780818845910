import api from "../axios_service.js";

// Busca Todas Midias Sociais
export async function fetchMidiasSociaisCalendarios(filtros) {
  const response = await api.get(
    `/gestor/clientes-calendarios${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca uma midia social
export async function fetchMidiaSocialCalendario(id) {
  const response = await api.get(`/gestor/clientes-calendarios/${id}`);
  return response.data.result;
}

// Atualiza uma midia social
export function putMidiaSocialCalendario(id, midia) {
  let body = {};
  for (let key in midia) {
    body[key] = midia[key];
  }
  return api.put(`/gestor/clientes-calendarios/${id}`, body);
}

// Cria uma nova midia social
export function postMidiaSocialCalendario(midia) {
  let body = {};
  for (let key in midia) {
    body[key] = midia[key];
  }
  return api.post("/gestor/clientes-calendarios/add", body);
}
